import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import ArticleCard from '../components/articles/BlogArticleCard'
import { getRandomBackground } from '../utils'
import _ from 'lodash'
import PageHeader from '../components/general/PageHeader'

const TagProfileTemplate = (props) => {
  const { tag, helmet, allArticles } = props
  let { articles } = tag
  const sortedArticles = _.filter(
    _.orderBy(articles, ['publishDate'], ['desc']),
    (article) => {
      return (
        new Date(article.publishDate) < new Date() &&
        article.status === 'Published'
      )
    }
  )
  sortedArticles.forEach((tagArticle) => {
    let { node = {} } =
      allArticles.find((x) => x.node.id === 'Article_' + tagArticle.id) || {}
    tagArticle['author'] = node.author
  })

  return (
    <section>
      {helmet || ''}
      <PageHeader className="hassan_3" backgroundImage={getRandomBackground()}>
        {tag.tagName}
      </PageHeader>

      <div className="columns is-gapless">
        <div className="container">
          <div className="column is-12">
            <div className="column is-12">
              {sortedArticles && sortedArticles.length && (
                <h1 className="title">الكتابات</h1>
              )}
            </div>
            <div className="columns is-gagless is-multiline">
              {sortedArticles.map((article) => (
                <ArticleCard
                  key={article.id}
                  showFeaturedImage
                  tag={tag}
                  author={article.author}
                  post={article}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

TagProfileTemplate.propTypes = {
  helmet: PropTypes.object,
  tag: PropTypes.shape({
    tagName: PropTypes.string,
    articles: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        slug: PropTypes.string,
        body: PropTypes.string,
        excerpt: PropTypes.string,
      })
    ),
  }),
  allArticles: PropTypes.array,
}

const TagPage = ({ data }) => {
  const { strapiTags: tag, allStrapiArticle: all } = data

  return (
    <Layout>
      <TagProfileTemplate
        tag={tag}
        helmet={
          <Helmet titleTemplate="%s | مركز الدراسات السودانية">
            <title>{`${tag.tagName}`}</title>
          </Helmet>
        }
        allArticles={all.edges}
      />
    </Layout>
  )
}

TagPage.propTypes = {
  data: PropTypes.shape({
    strapiTags: PropTypes.object,
    allStrapiArticle: PropTypes.object,
  }),
}

export default TagPage

export const pageQuery = graphql`
  query TagByID($id: String!, $name: String!) {
    strapiTags(id: { eq: $id }) {
      id
      strapiId
      tagName
      articles {
        excerpt
        id
        publishDate
        slug
        title
        body
        status
      }
      updated_at
      created_at
    }

    allStrapiArticle(
      filter: { tags: { elemMatch: { tagName: { eq: $name } } } }
    ) {
      edges {
        node {
          author {
            name
            id
            screenName
          }
          title
          id
          strapiId
        }
      }
    }
  }
`
